@import url('https://fonts.googleapis.com/css2?family=Tiny5&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tiny5', sans-serif;
}

body,
html,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.app-container {
  position: relative;
  height: 100%;
  width: 100%;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  color: white;
  font-family: 'Tiny5', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 4rem;
}
